import React from 'react'
import EditFactReplaceFormJSON from '../forms/EditFactReplaceFormJSON';

export default function EditFactJSON({ factDetails, setData, setFactsCount, setIsModalOpen }) {
    return (
        <EditFactReplaceFormJSON
            factDetails={factDetails}
            setData={setData}
            setFactsCount={setFactsCount}
            setIsModalOpen={setIsModalOpen}
        />
    )
}
