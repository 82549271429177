
// <--------- Url --------->
// export const BASE_URL = 'http://127.0.0.1:8080';
// export const BASE_URL = 'https://api.dev.factaa.com';
export const BASE_URL = 'https://api.factaa.com';
export const FACT_IMAGE_URL = `${BASE_URL}/`;
export const CATEGORY_IMAGE_URL = `${BASE_URL}/`;
export const POFILE_IMAGE_URL = `${BASE_URL}/`;

// <--------- Repair File Url --------->
export const REPAIR_FILE_URL = 'http://77.37.63.64:8104/file/repair';

// <--------- Tokens --------->
export const ACCESS_TOKEN = 'FACTAA_ACCESS_TOKEN';
export const REFRESH_TOKEN = 'FACTAA_REFRESH_TOKEN';

// <--------- App Theme --------->
export const APP_THEME = 'FACTAA_APP_THEME';

export const GOOGLE_CLIENT_ID = '779276582791-o6j3geiuge820jpkt541u2og9lladm2m.apps.googleusercontent.com';

// <--------- Snackbar Variant --------->
export const SUCCESS_VARIANT = 'SUCCESS';
export const WARNING_VARIANT = 'WARNING';
export const ERROR_VARIANT = 'ERROR';
export const NO_CONNECTION_VARIANT = 'NO_CONNECTION';

// <--------- Snackbar Message --------->
export const SOMETHING_WENT_WRONG = 'Oops! Something Went Wrong';

// <--------- Image Info --------->
export const FACT_IMAGE_INFO = {
    maxWidth: 1080,
    ratio: 16 / 9
}

export const CATEGORY_IMAGE_INFO = {
    maxWidth: 600,
    ratio: 3 / 4
}

export const QUIZ_IMAGE_INFO = {
    maxWidth: 400,
    ratio: 1 / 1
}

export const PROFILE_IMAGE_INFO = {
    maxWidth: 600,
    ratio: 1 / 1
}

// // <--------- Download/Open App --------->
// export const downloadOrOpenApp = () => {
//     window.location.href = 'https://bma.ifalcon.net/app/Home';
//     setTimeout(() => {
//         const userAgent = navigator.userAgent.toLowerCase();
//         if (userAgent.includes('android')) {
//             window.location.href = 'https://play.google.com/store/apps/details?id=com.example.myapp';
//         } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
//             window.location.href = 'https://apps.apple.com/us/app/your-app-name/id123456789';
//         }
//     }, 100);
// };