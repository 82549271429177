import React, { useEffect, useState } from 'react'
import { Icon } from '@iconify/react';
import FactListsOfUserDataTable from '../dataTables/FactListsOfUserDataTable';
import { factStatisticsOverviewList } from '../../constants/dummyData';
import { getUserFactsCountApi } from '../../rest/FactApi';

export default function FactsListOfUser({ currentUser }) {
    const [isLoading, setIsLoading] = useState(true);
    const [factsCount, setFactsCount] = useState([]);

    const getFactCount = (status) => factsCount?.find(option => option.status === status);

    // <--------- Fetch Facts Count --------->
    const fetchFactsCount = async () => {
        const result = await getUserFactsCountApi(currentUser?.id);
        if (result?.status === 200) {
            const totalFactsCount = result.data.statusCount.reduce((count, item) => count + item.count, 0);
            setFactsCount([...result.data.statusCount, { status: 'TOTAL', count: totalFactsCount }]);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFactsCount();
    }, []);

    return (
        <div className='p-8 min-h-[90vh]'>
            <div className='grid grid-cols-4 gap-6'>
                {
                    !isLoading ? (
                        factStatisticsOverviewList.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center h-28 bg-gray-50 rounded-xl'>
                                    <div className='flex items-center h-16 ml-8'>
                                        <div style={{ backgroundColor: item.iconBackgroundColor }} className={`flex justify-center items-center rounded-full h-14 w-14`}>
                                            <Icon
                                                icon={item.icon}
                                                color={item.iconColor}
                                                fontSize={26}
                                                strokeWidth={20}
                                            />
                                        </div>
                                        <div className='ml-6'>
                                            <div className='font-quicksand font-bold text-xl'>{getFactCount(item.statusCode)?.count || 0}</div>
                                            <div className='font-quicksand text-gray-400 font-bold text-sm'>{item.statusName}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        factStatisticsOverviewList.map((item, index) => {
                            return (
                                <div key={index} className='flex items-center h-28 bg-gray-50 rounded-xl'>
                                    <div className='flex items-center h-16 ml-8'>
                                        <div className={`flex justify-center items-center bg-gray-100 rounded-full h-14 w-14`}>
                                        </div>
                                        <div className='ml-6'>
                                            <div className='h-4 w-14 bg-gray-100 rounded-lg'></div>
                                            <div className='h-4 w-28 bg-gray-100 rounded-lg mt-2'></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
            <FactListsOfUserDataTable
                currentUser={currentUser}
                setFactsCount={setFactsCount}
            />
        </div>
    )
}
