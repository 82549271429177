import { getHighestRole } from "../utils/rolePermissionHandler";
import { factInstance } from "./AxiosInstance";

// <--------- Add New Fact --------->
export const addNewFactApi = async (body) => {
    try {
        const result = await factInstance.post('facts', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Repost Fact --------->
export const repostFactApi = async (factId, body) => {
    try {
        const result = await factInstance.put(`facts/${factId}`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Fact --------->
export const updateFactApi = async (factId, body) => {
    try {
        const result = await factInstance.patch(`facts/${factId}`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get All Facts --------->
export const getAllFactsApi = async (user, userId, statuses = [], categories, page, size) => {
    const params = new URLSearchParams();

    if (statuses.length > 0) {
        params.append('statuses', statuses.join(','));
    }
    if (categories.length > 0) {
        params.append('categories', categories.join(','));
    }
    params.append('page', page);
    params.append('size', size);

    if (userId || getHighestRole(user?.roles) === 'CONTRIBUTOR') {
        if (userId) return await getAllFactsByUserApi(params, userId);
        else return await getAllFactsByUserApi(params, user?.id);
    }
    else {
        try {
            const result = await factInstance.get(`facts?${params.toString()}`);
            return result;
        } catch (error) {
            return error?.response;
        }
    }

}

// <--------- Get All Facts By User --------->
export const getAllFactsByUserApi = async (params, userId) => {
    try {
        const result = await factInstance.get(`facts/users/${userId}?${params.toString()}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get All Facts --------->
export const getAllFactsWithSearchApi = async (query = '', userIds = [], statuses = [], categories = [], page, size) => {
    const params = new URLSearchParams();

    if (query) {
        params.append('query', query);
    }
    if (statuses.length > 0) {
        params.append('statuses', statuses.join(','));
    }
    if (categories.length > 0) {
        params.append('categories', categories.join(','));
    }
    if (userIds.length > 0) {
        params.append('users', userIds.join(','));
    }

    params.append('page', page);
    params.append('size', size);

    try {
        const result = await factInstance.get(`facts/search?${params.toString()}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get Single Fact --------->
export const getSingleFactApi = async (factId) => {
    try {
        const result = await factInstance.get(`facts/${factId}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Cover --------->
export const updateCoverApi = async (factId, body) => {
    try {
        const result = await factInstance.put(`facts/${factId}/cover`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Update Fact Status --------->
export const updateFactStatusApi = async (factId, body) => {
    try {
        const result = await factInstance.patch(`facts/${factId}/status`, body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Fact Log --------->
export const getFactLogApi = async (factId, page, size) => {
    try {
        const result = await factInstance.get(`facts/${factId}/logs?page=${page}&size=${size}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get the Range for Fact of the Day --------->
export const getFactDayRangeApi = async (firstDate, lastDate) => {
    try {
        const result = await factInstance.get(`day-facts?fromDate=${firstDate}&toDate=${lastDate}`);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Post Fact of the Day --------->
export const postFactOfTheDayApi = async (body) => {
    try {
        const result = await factInstance.put('day-facts', body);
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get Facts Count --------->
export const getFactsCountApi = async () => {
    try {
        const result = await factInstance.get('facts/count');
        return result;
    } catch (error) {
        return error?.response;
    }
}

// <--------- Get User Facts Count --------->
export const getUserFactsCountApi = async (userId) => {
    try {
        const result = await factInstance.get(`facts/users/${userId}/count`);
        return result;
    } catch (error) {
        return error?.response;
    }
}